import React, { ReactElement } from "react";
import styles from "./style.module.scss";
export default function HomeownersInsurance(): ReactElement {
    return (
        <section
            className={` lg:mt-28 mt-16 md:p-20 py-10 px-5 ${styles["mainSection"]}`}
        >
            <div className="  mx-auto  text-[#fff]  sm:text-center">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]  ">
                    Differentiating Home Warranties From Homeowners' Insurance
                </h2>
                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    While homeowners' insurance focuses on protecting your
                    home's structure and property, a home warranty provides
                    additional coverage for your appliances and systems. It
                    serves as a complementary service to homeowners' insurance,
                    addressing a different aspect of your home's protection.
                    It's important to note that manufacturer warranties may
                    already cover some of your home systems if you've recently
                    purchased a new home.
                </p>
            </div>
        </section>
    );
}
